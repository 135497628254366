module.exports = [{
      plugin: require('/Users/gotokatsuya/develop/gotokatsuya/hina.design/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/gotokatsuya/develop/gotokatsuya/hina.design/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-111100897-1","head":false,"anonymize":true},
    },{
      plugin: require('/Users/gotokatsuya/develop/gotokatsuya/hina.design/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
